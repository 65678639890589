<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
    :close-on-content-click="false"
    min-width="370px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="primary"
        :content="$store.getters['notifications/getNewNotifs']"
        :value="$store.getters['notifications/getNewNotifs']"
        overlap
      >
        <v-icon v-bind="attrs" v-on="on" @click="onShowNotifications">
          {{ icons.mdiBellOutline }}
        </v-icon>
      </v-badge>
    </template>
    <v-card class="app-bar-notification-content-container">
      <v-list class="py-0">
        <!-- Header -->
        <v-list-item class="d-flex" link>
          <div class="d-flex align-center justify-space-between flex-grow-1">
            <span class="font-weight-semibold">Notificaciónes</span>
          </div>
        </v-list-item>
        <v-divider></v-divider>

        <perfect-scrollbar class="ps-user-notifications" :options="perfectScrollbarOptions">
          <!-- Notifications -->
          <template v-for="(notification, index) in notifications">
            <v-list-item :key="notification._id" link>
              <!-- Content -->
              <v-list-item-content class="d-block">
                <v-list-item-title class="text-sm font-weight-semibold">
                  {{ notification.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-sm">
                  {{ notification.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <!-- Item Action/Time -->
              <v-list-item-action>
                <span class="text--secondary text-xs">{{ date(notification.createdAt).locale('es').fromNow() }}</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="deleteNotification(notification._id)" v-bind="attrs" v-on="on">{{
                      icons.mdiCloseCircle
                    }}</v-icon>
                  </template>
                  <span>Marcar como leído</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </perfect-scrollbar>

        <v-list-item class="read-all-btn-list-item" v-if="!notifications.length">
          <span style="width: 100%" class="text-center">¡Estás al día!</span>
        </v-list-item>
        <v-list-item v-else class="read-all-btn-list-item">
          <v-btn block color="primary" @click="deleteAll"> Marcar todas como leídas </v-btn>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiCloseCircle, mdiBellOutline } from '@mdi/js'
import { getInitialName } from '@core/utils'
import { ref, inject } from 'vue'
import store from '@/store'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  setup() {
    const date = inject('date')
    const notifications = ref([])
    const notificationsToDelete = ref([])

    const perfectScrollbarOptions = {
      maxScrollbarLength: 100,
      wheelPropagation: false,
    }

    store.dispatch('notifications/fetchNotifications')

    // fetch data
    const deleteNotification = id => {
      notificationsToDelete.value.push(id)
      store.dispatch('notifications/deleteLocalNotification', id)
      if (notificationsToDelete.value.length === 1) {
        setTimeout(() => {
          store.dispatch('notifications/deleteNotifications', notificationsToDelete.value)
          notificationsToDelete.value = []
        }, 2000)
      }
    }

    const deleteAll = () => {
      store.dispatch('notifications/deleteAll')
      notifications.value = []
    }

    const onShowNotifications = () => {
      notifications.value = []
      notifications.value = store.getters['notifications/getNotifications']
      store.commit('notifications/cleanNewNotifs')
    }
    return {
      onShowNotifications,
      notifications,
      date,
      deleteAll,
      deleteNotification,
      getInitialName,
      perfectScrollbarOptions,

      icons: {
        mdiCloseCircle,
        mdiBellOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 50);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
