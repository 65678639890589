import { mdiSchool } from '@mdi/js'

export default [
  {
    subheader: 'Supervisor',
  },
  {
    title: 'Grupos Supervisor',
    to: { name: 'apps-supervisorcourses' },
    icon: mdiSchool,
    resource: 'supervisorcourses',
    action: 'nav',
  },
]
