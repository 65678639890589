import { mdiAccountOutline, mdiDomain } from '@mdi/js'

export default [
  {
    subheader: 'Administrador',
  },
  {
    title: 'Usuarios',
    icon: mdiAccountOutline,
    to: 'apps-user-list',
    resource: 'user',
    action: 'nav',
  },
  {
    title: 'Empresas',
    icon: mdiDomain,
    to: 'apps-company-list',
    resource: 'company',
    action: 'nav',
  },
]
