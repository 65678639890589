const apps = [
  //
  //* ——— User ——————————————————
  //

  {
    path: '/apps/user/list',
    name: 'apps-user-list',
    component: () => import('@/views/apps/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
      resource: 'user',
      action: 'read',
    },
  },
  {
    path: '/apps/user/view/:id?',
    name: 'apps-user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      resource: 'user',
      action: 'read',
    },
  },

  //
  //* ——— Group ——————————————————
  //

  {
    path: '/apps/group/list',
    name: 'apps-group-list',
    component: () => import('@/views/apps/group/group-list/GroupList.vue'),
    meta: {
      layout: 'content',
      resource: 'group',
      action: 'read',
    },
  },
  {
    path: '/apps/group/view/:id?',
    name: 'apps-group-view',
    component: () => import('@/views/apps/group/group-view/GroupView.vue'),
    meta: {
      layout: 'content',
      resource: 'group',
      action: 'read',
    },
  },

  //
  //* ——— Company ——————————————————
  //

  {
    path: '/apps/company/list',
    name: 'apps-company-list',
    component: () => import('@/views/apps/company/company-list/CompanyList.vue'),
    meta: {
      layout: 'content',
      resource: 'company',
      action: 'read',
    },
  },
  {
    path: '/apps/company/view/:id?',
    name: 'apps-company-view',
    component: () => import('@/views/apps/company/company-view/CompanyView.vue'),
    meta: {
      layout: 'content',
      resource: 'company',
      action: 'read',
    },
  },

  //
  //* ——— Regular ——————————————————
  //

  {
    path: '/apps/regular/list',
    name: 'apps-regular-list',
    component: () => import('@/views/apps/regular/regular-list/RegularList.vue'),
    meta: {
      layout: 'content',
      resource: 'regular',
      action: 'read',
    },
  },
  {
    path: '/apps/regular/view/:id?',
    name: 'apps-regular-view',
    component: () => import('@/views/apps/regular/regular-view/RegularView.vue'),
    meta: {
      layout: 'content',
      resource: 'regular',
      action: 'read',
    },
  },

  //
  //* ——— Teacher ——————————————————
  //

  {
    path: '/apps/mygroups',
    name: 'apps-teacher-mygroups',
    component: () => import('@/views/apps/teacher/TeacherGroups.vue'),
    meta: {
      layout: 'content',
      resource: 'mygroups',
      action: 'read',
    },
  },

  //
  //* ——— Student ——————————————————
  //

  {
    path: '/mis-cursos',
    name: 'apps-mycourses',
    component: () => import('@/views/apps/student/StudentGroups.vue'),
    meta: {
      layout: 'content',
      resource: 'mycourses',
      action: 'read',
    },
  },

  //
  //* ——— Supervisor ——————————————————
  //

  {
    path: '/cursos',
    name: 'apps-supervisorcourses',
    component: () => import('@/views/apps/supervisor/SupervisorGroups.vue'),
    meta: {
      layout: 'content',
      resource: 'supervisorcourses',
      action: 'read',
    },
  },

  //
  //* ——— Profile ——————————————————
  //

  {
    path: '/perfil',
    name: 'apps-profile',
    component: () => import('@/views/apps/profile/Profile.vue'),
    meta: {
      layout: 'content',
      resource: 'profile',
      action: 'read',
    },
  },
]

export default apps
