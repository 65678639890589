import ability from '@/plugins/acl/ability'
import { initialAbility } from '@/plugins/acl/config'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    user: {},
    token: '',
    userAbility: [
      {
        action: 'read',
        subject: 'Public',
      },
    ],
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getToken(state) {
      return state.token
    },
    getUserAbility(state) {
      return state.userAbility
    },
    isLoggedIn(state) {
      return state.user && state.token && state.userAbility
    },
  },
  mutations: {
    setUserData: (state, data) => {
      const { user, token, userAbility } = data

      state.user = user
      state.token = token
      state.userAbility = userAbility

      ability.update(userAbility)
    },
    setAvatar: (state, avatar) => {
      state.user.avatar = avatar
    },
    clear(state) {
      state.user = {}
      state.token = ''
      state.userAbility = initialAbility
    },
  },
  actions: {
    setUserSocket: ({ state, rootState }) => {
      if (state.user._id) rootState.socket.io.emit('setUserId', state.user._id)
    },
    socket_sendData: ({ state, rootState }) => {
      rootState.socket.io.emit('sendData', state.data)
    },

    fetchUsers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/getUsers`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/getUser/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourses(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/getCourses/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSupervisorCourses(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/getSupervisorCourses/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProfile(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/getProfile`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/update/${userData.user._id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProfile(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/updateProfile`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/auth/changePassword/${userData._id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/save`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBatchUsers(ctx, users) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/batchSave`, users)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMany(ctx, users) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/deleteMany`, users)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadAvatar(ctx, file) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/uploadAvatar`, file)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNotifications(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/updateNotifications/${userData.user}`, userData.notifications)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
