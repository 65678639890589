export default {
    namespaced: true,
    state: {
        content: '',
        color: '',
        timeout: ''
    },
    getters: {},
    mutations: {
        showMessage (state, payload) {
            state.content = payload.content
            state.color = payload.color
            state.timeout = payload.timeout
        }
    },
    actions: {},
}
  