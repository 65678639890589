<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
    <Snackbar></Snackbar>
    <AlertConfirm></AlertConfirm>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from 'vue'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'

import Snackbar from '@/utils/Snackbar'
import AlertConfirm from '@/utils/AlertConfirm'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutBlank,
    Snackbar,
    AlertConfirm,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  background: rgba(68, 77, 97, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(68, 77, 97, 0.3);
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(68, 77, 97, 0.5);
}
</style>
