import axios from '@axios'

export default {
  namespaced: true,
  state: {
    notifications: [],
    newNotifs: 0,
  },
  getters: {
    getNotifications(state) {
      return state.notifications
    },
    getNewNotifs(state) {
      return state.newNotifs
    },
  },
  mutations: {
    SOCKET_recieveNotifications(state, notification) {
      state.notifications.push(notification)
      state.newNotifs++
    },
    setNotifications(state, value) {
      state.notifications = value
    },
    cleanNewNotifs(state, value) {
      state.newNotifs = 0
    },
  },
  actions: {
    async fetchNotifications({ state }) {
      let notifs = await axios.get('/notification/')
      state.notifications = notifs.data.notifications
      state.newNotifs = notifs.data.notifications.length
    },
    deleteNotifications({ state }, ids) {
      axios.delete(`/notification/${ids.join('&')}`)
    },
    deleteLocalNotification({ state }, id) {
      state.notifications.splice(
        state.notifications.findIndex(n => n._id == id),
        1,
      )
    },
    deleteAll({ state }) {
      axios.delete('/notification/')
      state.notifications = []
    },
  },
}
