import { Ability, AbilityBuilder } from '@casl/ability'
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import alertConfirm from './alertConfirm'
import app from './app'
import notifications from './notifications'
import snackbar from './snackbar'
import user from './user'
const { can } = new AbilityBuilder(Ability)

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'app',
  storage: window.localStorage,
  modules: ['user', 'notifications'],
})

export default new Vuex.Store({
  state: {
    socket: {},
    apidomain: process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://api-students.sondert.com',
    roleOptions: [
      { title: 'Admin', value: 'admin' },
      { title: 'Docente', value: 'teacher' },
      { title: 'Alumno', value: 'student' },
      { title: 'Supervisor', value: 'companySupervisor' },
      { title: 'Asesor Académico', value: 'academicAdvisor' },
    ],
  },
  mutations: {},
  actions: {
    setSocket: ({ state, dispatch }, socket) => {
      state.socket = socket
      dispatch('user/setUserSocket')
    },
  },
  getters: {
    roleOptions(state) {
      return state.roleOptions.filter(r => can(r.value, 'roles'))
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user,
    snackbar,
    alertConfirm,
    notifications,
  },
  plugins: [vuexLocal.plugin],
})
